/**
 * This file is not covered by testing. It should be treated as very
 * fragile and all changes should be fully understood.
 *
 * It is not currently tested because the testing overhead
 * of setting up the Okta Widget and confirming that it has
 * been properly hooked into is very difficult and is unlikely
 * to be solved in this way. At some point, UI testing should cover
 * this file, but care should still be taken with this file either way.
 */
/*eslint no-console: ["error", { allow: ["error"] }] */
import React from 'react';
import { hackyWidgetListener } from '../HackyInserter';
import { ResendPasswordResetBtn } from '../components/ResendPasswordResetBtn';
import { loginLabel, loginLabelLower } from '../../components/basic/LoginLabel';
import { OktaController, OktaEventContext, trySafely } from './shared';
import { markWithRole } from './a11y';
import {
  getClearedWidgetContent,
  removeFooterBackLinkIfExists,
  removeIcons,
  removeLinks,
  removeRememberMe
} from './dom-clean';
import {
  createHeader,
  createLinks,
  createRedirectLink,
  createRedirectSuccessMessage
} from './dom-build';
import {
  expandNeedHelp,
  insertMarkerIfMissing,
  setButtonsToUpper,
  setToAutocomplete,
  setToUpper
} from './dom-mod';
import { getUserNameFromQuery, getQueryString } from './url-util';
import { addUnloadEvent, registerTracking } from './tracking';
import { setPageLanguage } from './i18n';
import { DOM_FIND_ATTR, DomFind, DomFindPrints } from './dom-find';
import { Flow, FlowIds } from './flow';
import { trackingClient } from '../../ext/tracking';
import { setWebpackPublicFromBackend } from '../../utility/customize-webpack';
import { attemptRegisterForDevs } from '../../dev/dev-barrier/register';
import { cengageLogoUrl } from '../../components/basic/CengageLogo';
import {
  addListenerToCheckAlternateLoginId,
  checkForAlternateLoginAfterFailure
} from './alternate-loginid-lookup';
import { RecoveryTokenForm } from '../components/RecoveryTokenForm';
import { featureFlags } from '../../frontendBackend';
import {
  Alert,
  AlertVariant,
  Button,
  ButtonColor
} from 'react-magma-dom';
import { ActionButton } from '../../components/basic/ActionButton';
import { reactivateUser } from '../../api';

declare const OktaSignIn: any;

export let alternateLoginId = '';

export const setAlternateLoginId = (value: string) => {
  alternateLoginId = value;
};

/**
 * This is meant to allow for modification of existing frontend
 * assets that are outside of the scope of Cengage control.
 *
 * Important to note: This expects for an object to be provided
 * to it called "backend" (passed into this immediately executing
 * function at the bottom). This is created by the backend and should
 * be located at window.backend and is considered to be invalid
 * if not available.
 *
 * The object "frontend" is the "export" that is being made here.
 * As of now, the only expected export is a function called
 * "customizeExisting" which customizes existing assets. This is
 * called from the backend-generated html files at the appropriate
 * time.
 *
 * This should be considered stable and changes here should be
 * thoroughly checked.
 */

((frontend, backend) => {
  setWebpackPublicFromBackend();

  const bootstrapWidget = (context: OktaEventContext) => {
    const { controller } = context;

    trySafely(
      () => markWithRole('#okta-login-container > :first-child', 'main'),
      'Experienced some trouble when trying to add aria-role'
    );

    trySafely(
      () => setPageLanguage('en'),
      'Experienced some trouble when trying to set the pages language'
    );

    try {
      if (
        OktaController.primaryAuth === controller ||
        OktaController.idpDiscovery === controller ||
        OktaController.passwordResetEmailSent === controller ||
        OktaController.passwordExpired === controller
      ) {
        const createAccountLinksExist = !!document.querySelector(
          '.createAccount'
        );
        if (createAccountLinksExist) {
          removeLinks();
        }
        if (!window.backend.uxtheme.settings.registration.hidden) {
          createLinks();
        }
        removeFooterBackLinkIfExists();
        removeIcons();
        removeRememberMe(controller);
        setButtonsToUpper(controller);
        if (getQueryString().includes('emailAddress=') || getQueryString().includes('login_hint=')) {
          expandNeedHelp();
        }
      } else if (OktaController.forgotPassword === controller) {
        const authFooter = document.querySelector('.auth-footer');
        authFooter?.setAttribute('style', 'text-align: center;');
        document
          .querySelectorAll('.createAccount')
          .forEach(e => e?.parentNode?.removeChild(e));
        const emailButton = document.querySelector("[data-se='email-button']");
        if (emailButton) {
          emailButton.textContent = 'RESET VIA EMAIL';
        }
        removeIcons();
        if (featureFlags.isCheckForDistrictLoginUsers()) {
          addListenerToCheckAlternateLoginId();
        }
      } else if (OktaController.passwordReset === controller) {
        const signInLink: HTMLLinkElement | null = document.querySelector(
          'a.link.goto'
        );
        if (signInLink) {
          signInLink.innerText = 'Back to Sign in';
          signInLink.setAttribute(DOM_FIND_ATTR, DomFindPrints.backToSignIn);
        }
        setToUpper("[data-type='save']");
        const authContent = document.querySelector('.auth-content');
        authContent?.setAttribute('style', 'padding: 0 15px 20px;');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fn = {} as any;

  fn.success = {};
  fn.success[FlowIds.login] = function success(res: any) {
    if (res.status === 'SUCCESS') {
      res.session.setCookieAndRedirect(backend.redirectUrl);
    }
  };
  fn.success[FlowIds.addPassword] = fn.success[
    FlowIds.resetPassword
  ] = function success(res: any) {
    if (res.status === 'FORGOT_PASSWORD_EMAIL_SENT') {
      // maybe some tweaking here if we need it?
      return;
    }
    if (res.status === 'SUCCESS') {
      if (Flow.isAddPassword()) {
        // This is awful, but given how okta handles these success renders,
        // this is how it has to be done.
        trackingClient.event.completed.loadViewPage();
        addUnloadEvent(() => trackingClient.event.completed.unloadViewPage());
      }

      const widgetContent = getClearedWidgetContent();
      const resetSuccessHeader = createHeader();
      const redirectMessage = createRedirectSuccessMessage(() => {
        res.session.setCookieAndRedirect(backend.redirectUrl);
      });
      const redirectLink = createRedirectLink();

      widgetContent?.appendChild(resetSuccessHeader);
      widgetContent?.appendChild(redirectMessage);
      widgetContent?.appendChild(redirectLink);

      // This is awful, see note above about why this has to be done here
      redirectLink.addEventListener('click', () => {
        trackingClient.event.completed.redirectClick();
        res.session.setCookieAndRedirect(backend.redirectUrl);
      });
    }
  };

  fn.error = {};
  fn.error['default'] = function error(res: any) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(res, undefined, 2));
  };

  frontend.customizeExisting = () => {
    // eslint-disable-next-line no-console
    console.log(
      `%c
    .==========================================.
    |                                          |
    | Running customization of existing assets |
    |                                          |
    '=========================================='
    `,
      'color: #1d7288; font-family:monospace; font-weight: bolder'
    );

    const config = {
      i18n: {
        en: {
          'primaryauth.title': 'Sign in',
          'primaryauth.username.placeholder': `${loginLabel}`,
          'primaryauth.username.tooltip': `${loginLabel}`,
          'password.forgot.email.or.username.placeholder': `${loginLabel}`,
          'password.forgot.email.or.username.tooltip': `${loginLabel}`,
          'error.username.required': `Please enter an ${loginLabelLower}`,
          'model.validation.field.username': `Please check your ${loginLabelLower}`,
          'password.reset.title': 'Set password', //NOSONAR
          'password.reset.title.generic': 'Set your password', //NOSONAR
          'password.reset': 'Set password' //NOSONAR
        }
      }
    } as any;
    config.baseUrl = backend.oktaBaseUrl;
    config.recoveryToken = backend.recoveryToken;
    config.helpLinks = {
      help: window?.backend?.cengagePhoneHelpUrl
    };
    config.logo = cengageLogoUrl();
    config.logoText = 'Cengage Learning'; //logoText sets alt attribute and Okta appends " logo" to contents of logoText
    const username = '' || getUserNameFromQuery();
    const wfCompletionContext = `${window.location.protocol}//${window.location.host}/completeWorkforceFederatedLogin${window.location.search}`;
    config.username = username;
    config.features = {
      idpDiscovery: true
    };
    config.idpDiscovery = {
      requestContext: wfCompletionContext
    };
    let loginId = '';
    config.transformUsername = (userId: any, operation: any) => {
      loginId = alternateLoginId ? alternateLoginId : userId;
      return loginId;
    };
    const signIn = new OktaSignIn(config);
    signIn.renderEl(
      { el: '#okta-widget-container' },
      fn.success[backend.flowId] || fn.success.default,
      fn.error[backend.flowId] || fn.error.default
    );

    signIn.on('afterRender', bootstrapWidget);

    signIn.on('afterError', async (context: any, error: any) => {
      if (context.controller === 'primary-auth' && error.statusCode === 401 && error.name === 'AuthApiError' && !alternateLoginId && featureFlags.isCheckForDistrictLoginUsers()) {
        await checkForAlternateLoginAfterFailure();
      }
    });

    /* eslint-disable react/display-name */
    // For some reason it thinks we're trying to declare
    // new components here....
    hackyWidgetListener(signIn, {
      afterRender: {
        'password-reset-email-sent': () => {
          const backButton = document.querySelector("[data-se='back-button']");
          if (backButton) {
            backButton.remove();
          }
          const emailExpln = document.querySelector('.o-form-explain');
          if (emailExpln) {
            emailExpln.textContent = 'A password reset email was sent to the email address associated with ' + loginId + '.  Use the password reset link or enter the recovery code included in the email.';
          }
          return (
            <React.Fragment>
              <RecoveryTokenForm username={loginId}/>
              <hr data-width='100%'/>
              <ResendPasswordResetBtn username={loginId}/>
              <Button isFullWidth color={ButtonColor.subtle} className="button-secondary"
                onClick={() => {
                  (document.location as any) = '/login';
                }}>BACK TO SIGN IN
              </Button>
            </React.Fragment>
          );
        }
      },
      afterError: {
        'recovery-loading': () => {
          const errorContainer = document.querySelector("[data-se='o-form-error-container']");
          if (errorContainer) {
            const formContainer = errorContainer.parentNode;
            if (formContainer) {
              errorContainer.remove();
              const isRegistering = document.location.pathname.indexOf('welcome') > 0;
              if ( isRegistering ) {
                return (
                  <React.Fragment>
                    <h2 data-se="o-form-head" className="okta-form-title o-form-head">Cannot Activate Account</h2>
                    <Alert variant={AlertVariant.danger} className="alfred-password-set-error">
                      <p style={{ paddingLeft: '10px' }}>Your account activation link and activation code has expired, has already been used, or is not valid.</p>
                    </Alert>
                    <br/>
                    <ActionButton
                      block
                      onAction={() => {
                        (document.location as any) = document.referrer;
                        return reactivateUser('{loginId}');
                      }}
                      onActionChildren="Sending..."
                      toastOnFailure="Email sending error"
                      toastOnSuccess="Email sent!"
                      testId="resend-email"
                      color={ButtonColor.secondary}
                    >
                      RESEND ACCOUNT ACTIVATION EMAIL
                    </ActionButton>
                    <Button isFullWidth color={ButtonColor.subtle} className="button-secondary"
                      onClick={() => {
                        (document.location as any) = '/login' + document.location.search;
                      }}>BACK TO SIGN IN
                    </Button>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <h2 data-se="o-form-head" className="okta-form-title o-form-head">Cannot Reset Password</h2>
                    <Alert variant={AlertVariant.danger} className="alfred-password-set-error">
                      <p style={{ paddingLeft: '10px' }}>Your password reset link and recovery code has expired, has already been
                        used, or is not valid. Go back to Sign In and click Forgot Password again
                        to receive a new password reset email.</p>
                    </Alert>
                    <br/>
                    <Button isFullWidth color={ButtonColor.subtle} className="button-secondary"
                      onClick={() => {
                        (document.location as any) = '/login' + document.location.search;
                      }}>BACK TO SIGN IN
                    </Button>
                  </React.Fragment>
                );
              }
            }
          }
        }
      }
    });
    /* eslint-enable react/display-name */

    attemptRegisterForDevs();

    // Should go last to ensure other event handlers run first
    registerTracking(signIn);

    // Add the marker at the end of our flow to ensure we succeeded
    // Can be queried with: span[data-type="marker"][data-testid="modified-widget"]
    insertMarkerIfMissing('modified-widget');
  };
})(
  (window.frontend = window.frontend || {}),
  // Provided by the backend in static html
  window.backend
);