import { Callback } from './shared';
import { DOM_FIND_ATTR, DomFindPrints } from './dom-find';
import { buildPathTo } from '../../navigation/path-builder';

export const createHeader = () => {
  const resetSuccess = document.createElement('h1');
  resetSuccess.classList.add('okta-form-title', 'o-form-head');
  resetSuccess.textContent = "You're all set!";
  return resetSuccess;
};

export const createRedirectSuccessMessage = (callAfterDelay: Callback) => {
  const redirectMessage = document.createElement('p');
  redirectMessage.classList.add('okta-form-subtitle', 'o-form-explain');
  let timeLeft = 5;
  const update = () => {
    if (0 === timeLeft) {
      callAfterDelay();
    }

    redirectMessage.textContent = `Your password has been set, you're signed in, and you will now be redirected in ${timeLeft} seconds...`;

    timeLeft -= 1;
    if (0 <= timeLeft) {
      setTimeout(update, 1000);
    }
  };
  update();
  return redirectMessage;
};

export const createRedirectLink = () => {
  const redirectLink = document.createElement('a');
  redirectLink.className = 'link';
  redirectLink.setAttribute('href', 'javascript:void(0)');
  redirectLink.setAttribute(DOM_FIND_ATTR, DomFindPrints.notRedirected);
  redirectLink.innerText =
    'If you are not automatically redirected, click here';
  return redirectLink;
};

export const createStudentLinkId = 'student-registration';
export const createLinks = () => {
  const widgetContentContainer = document.querySelector('.auth-content-inner');
  const createStudentAccount = document.createElement('div');
  const hrElement = document.createElement('hr');
  hrElement.setAttribute('aria-hidden', 'true');
  createStudentAccount.append(hrElement);

  const studentRegisterLink = document.createElement('a');
  studentRegisterLink.id = createStudentLinkId;
  studentRegisterLink.className = 'link';
  studentRegisterLink.href = buildPathTo.register.root();

  studentRegisterLink.innerText = 'Create Account';
  createStudentAccount.className = 'createAccount';
  createStudentAccount.append('New to Cengage? ');
  createStudentAccount.append(studentRegisterLink);

  widgetContentContainer?.append(createStudentAccount);
};

/**
 * Copied from src/components/meta/Marker.tsx
 * @param testId Test id for selection
 */
export const generateMarker = (testId: string) => {
  const result = document.createElement('span');
  const attribs = [
    ['aria-hidden', 'true'],
    ['data-type', 'marker'],
    ['data-testid', testId],
    ['data-last-render', `${Date.now()}`],
    ['data-last-mount', `${Date.now()}`]
  ] as [string, string][];
  for (const [k, v] of attribs) {
    result.setAttribute(k, v);
  }
  return result;
};

export const createOktaFormError = (errorMessage: string) => {
  const errorParagraph = document.createElement('p');
  errorParagraph.innerText = errorMessage;
  const errorIconSpan = document.createElement('span');
  errorIconSpan.classList.add('icon', 'error-16');
  const innerDiv = document.createElement('div');
  innerDiv.classList.add('okta-form-infobox-error', 'infobox', 'infobox-error');
  innerDiv.role = 'alert';
  innerDiv.appendChild(errorIconSpan);
  innerDiv.appendChild(errorParagraph);
  const result = document.createElement('div');
  result.appendChild(innerDiv);
  return result;
};

export const removeOktaFormErrorContents = (errorDiv: Element) => {
  if (errorDiv.children.length > 0) {
    errorDiv.removeChild(errorDiv.childNodes.item(0));
    errorDiv.classList.remove('o-form-has-errors');
  }
};