declare global {
  interface Window {
    backend: any;
    frontend: any;
  }
}

export interface Callback {
  (): void;
}

export enum OktaController {
  primaryAuth = 'primary-auth',
  idpDiscovery = 'idp-discovery',
  passwordReset = 'password-reset',
  forgotPassword = 'forgot-password',
  recoveryLoading = 'recovery-loading',
  passwordResetEmailSent = 'password-reset-email-sent',
  passwordExpired = 'password-expired'
}

// Copied from https://github.com/okta/okta-signin-widget#afterrender
// Copied from https://github.com/okta/okta-signin-widget#aftererror
export interface OktaEventContext {
  controller: string;
}

// Copied from https://github.com/okta/okta-signin-widget#aftererror
export interface OktaErrorData {
  name: string;
  message: string;
  statusCode: number;
  xhr: any;
}

// Copied from https://github.com/okta/okta-signin-widget#afterrender
export interface OktaRenderEventCallback {
  (context: OktaEventContext): void;
}

// Copied from https://github.com/okta/okta-signin-widget#aftererror
export interface OktaErrorEventCallback {
  (context: OktaEventContext, error: OktaErrorData): void;
}

// Copied from https://github.com/okta/okta-signin-widget#on and relevant events
export interface OktaWidget {
  on(event: 'afterRender', cb: OktaRenderEventCallback): void;
  on(event: 'afterError', cb: OktaErrorEventCallback): void;
}

export type ControllerMap<T> = {
  [P in OktaController]?: T;
};

export const trySafely = (cb: Callback, message: string) => {
  try {
    cb();
  } catch (ignored) {
    if (message) {
      // eslint-disable-next-line no-console
      console.warn(message, ignored);
    }
  }
};